import React, { useEffect } from "react";
import { api } from "../../api";
import { Paper, makeStyles } from "@material-ui/core";
import { ScadaEssence } from "../../features/mapping-essence-to-essence";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: 'column',
    padding: 10,
    gap: 10,
    margin: theme.spacing(1),
  },
}));

export const ScadaPage = () => {
  const styles = useStyles();

  return (
    <Paper className={styles.root}>
      <ScadaEssence />
    </Paper>
  );
};
