import React from "react";
import { Checkbox, Typography } from "antd";

const CheckboxGroup = Checkbox.Group;

export const plainOptions = [
  { label: "Наличие роутера", value: "isRouter" },
  { label: "Объект является ВОЦ", value: "isChatBot" },
  { label: "Наличие подсказчика", value: "isVoc" },
];

export const Attributes = ({ attributes = {}, onChangeAttributes }) => {
  const getChecklist = () => {
    const checkedList = [];
    plainOptions.forEach(({ value }) => {
      if (attributes[value]) {
        checkedList.push(value);
      }
    });

    return checkedList;
  };

  const onChange = (list) => {
    const attr = {};
    plainOptions.forEach(({ value }) => {
      attr[value] = false;
      if (list.includes(value)) {
        attr[value] = true;
      }
    });

    onChangeAttributes(attr);
  };

  return (
    <>
      <Typography.Paragraph strong>Атрибуты объекта:</Typography.Paragraph>
      <CheckboxGroup
        options={plainOptions}
        value={getChecklist()}
        onChange={onChange}
      />
    </>
  );
};
